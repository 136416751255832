import client from '../client';
import { getAuth } from 'firebase/auth';
import { AxiosResponse } from 'axios';

interface FeedbackResponse {
  success: boolean;
  message: string;
}

export const sendFeedback = async (
  name: string,
  from: string,
  subject: string,
  message: string
): Promise<FeedbackResponse> => {
  const endpoint = '/email';

  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error("User is not authenticated");
    }

    const token = await currentUser.getIdToken();

    const response: AxiosResponse<FeedbackResponse> = await client.post(
      endpoint, 
      {
        name: name,
        email: from,
        subject: subject,
        content: message,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.error("Error sending feedback:", error);
    return Promise.reject(error);
  }
};