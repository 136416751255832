import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  CircularProgress,
  Box,
  Button,
  DialogTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getPreview } from '../api/endpoints/generateDocumentOrPreview';
import PayButton from './PayButton';
import PayOrDownloadTemporary from './PayOrDownloadTemporary';

interface PreviewProps {
  id: string;
  pages: number;
}

const Preview: React.FC<PreviewProps> = ({ id, pages }) => {
  const [open, setOpen] = useState(false);
  const [imageSrcs, setImageSrcs] = useState<(string | null)[]>([]);
  const [loadingPages, setLoadingPages] = useState<boolean[]>([]);

  const fetchPreview = async (page: number) => {
    setLoadingPages((prev) => {
      const updated = [...prev];
      updated[page - 1] = true;
      return updated;
    });
    try {
      const imageBlob = await getPreview(id, page);
      const imageUrl = URL.createObjectURL(imageBlob);
      setImageSrcs((prev) => {
        const updated = [...prev];
        updated[page - 1] = imageUrl;
        return updated;
      });
    } catch (error) {
      console.error(`Error fetching preview for page ${page}:`, error);
      setImageSrcs((prev) => {
        const updated = [...prev];
        updated[page - 1] = null;
        return updated;
      });
    } finally {
      setLoadingPages((prev) => {
        const updated = [...prev];
        updated[page - 1] = false;
        return updated;
      });
    }
  };

  useEffect(() => {
    if (open) {
      const initialLoadingStates = Array(pages).fill(false);
      const initialImageSrcs = Array(pages).fill(null);
      setLoadingPages(initialLoadingStates);
      setImageSrcs(initialImageSrcs);
    }
    return () => {
      imageSrcs.forEach((src) => {
        if (src) URL.revokeObjectURL(src);
      });
    };
  }, [open, pages]);

  useEffect(() => {
    if (open) {
      for (let page = 1; page <= pages; page++) {
        fetchPreview(page);
      }
    }
  }, [open]);

  const handleOpenPreview = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        sx={{ marginBottom: 2, backgroundColor: "#069494", textTransform: 'none', borderRadius: 2 }}
        onClick={handleOpenPreview}
      >
        Preview
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
        PaperProps={{
          style: { maxHeight: '90vh' },
        }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="space-between">
          <span>Preview</span>
            <Box display="flex" alignItems="center" gap={1}>
              {/* <PayButton /> */}
              <PayOrDownloadTemporary />
              <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflowY: 'auto',
            padding: 2,
            gap: 2,
          }}
        >
          {Array.from({ length: pages }).map((_, index) => (
            <Box key={index} sx={{ width: '100%', textAlign: 'center' }}>
              {loadingPages[index] ? (
                <CircularProgress />
              ) : imageSrcs[index] ? (
                <img
                  src={imageSrcs[index]!}
                  alt={`Page ${index + 1} preview`}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                  }}
                />
              ) : (
                <Box>No preview available for page {index + 1}</Box>
              )}
            </Box>
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Preview;