import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Article } from '../model/Article';

interface ArticleContextProps {
  zipFile: File | null;
  setZipFile: (file: File | null) => void;
  publisher: string;
  setPublisher: (publisher: string) => void;
  journal: string;
  setJournal: (journal: string) => void;
  bibliography: string;
  setBibliography: (bibliography: string) => void;
  article: Article | null;
  setArticle: (article: Article | null) => void;
}

const ArticleContext = createContext<ArticleContextProps | undefined>(undefined);

export const ArticleProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [zipFile, setZipFile] = useState<File | null>(null);
  const [publisher, setPublisher] = useState<string>('');
  const [journal, setJournal] = useState<string>('');
  const [bibliography, setBibliography] = useState<string>('');
  const [article, setArticle] = useState<Article | null>(null);

  return (
    <ArticleContext.Provider
            value={{
                zipFile,
                setZipFile,
                publisher,
                setPublisher,
                journal,
                setJournal,
                bibliography,
                setBibliography,
                article,
                setArticle,
            }}
        >
            {children}
        </ArticleContext.Provider>
  );
};

export const useArticleContext = () => {
  const context = useContext(ArticleContext);
  if (!context) {
      throw new Error('useArticleContext must be used within an ArticleProvider');
  }
  return context;
};