import React, { createContext, useContext, useState } from 'react';

interface ErrorContextProps {
  generalError: string | null;
  instructionError: boolean;
  setGeneralError: (message: string | null) => void;
  showInstructionError: () => void;
  hideInstructionError: () => void;
}

const ErrorContext = createContext<ErrorContextProps | undefined>(undefined);

export const ErrorProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [generalError, setGeneralErrorState] = useState<string | null>(null);
  const [instructionError, setInstructionErrorState] = useState<boolean>(false);

  const setGeneralError = (message: string | null) => {
    setGeneralErrorState(message);
  };

  const showInstructionError = () => setInstructionErrorState(true);
  const hideInstructionError = () => setInstructionErrorState(false);

  return (
    <ErrorContext.Provider
      value={{
        generalError,
        instructionError,
        setGeneralError,
        showInstructionError,
        hideInstructionError,
      }}
    >
      {children}
    </ErrorContext.Provider>
  );
};

export const useErrorContext = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error('useErrorContext must be used within an ErrorProvider');
  }
  return context;
};