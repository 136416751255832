import React from "react";
import { Box, Paper, Typography, Link, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const VerifyEmail = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#f5f6f6",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          borderRadius: 2,
          backgroundColor: "#ffffff",
          fittocontent: "100%",
          textAlign: "center"
        }}
      >
        <Typography variant="h4" gutterBottom>
          Verify Email before login
        </Typography>
        <Button
          component={RouterLink}
          to="/login"
          variant="text"
          sx={{
            mt: 2,
            textTransform: 'none',
            color: 'grey',
            fontWeight: "bold",
          }}
        >
          Go to Login
        </Button>
      </Paper>
    </Box>
  );
};

export default VerifyEmail;