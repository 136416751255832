import React, { useEffect, useState } from "react";
import { Paper, Typography, Box, IconButton } from "@mui/material";
import { useArticleContext } from "../contexts/ArticleContext";
import { Chapter, SentenceContent, ImageContent, TableContent } from "../model/Chapter";
import JSZip from "jszip";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

declare global {
  interface Window {
    MathJax: any;
  }
}

const DisplayChapters = ({ chapters }: { chapters: Chapter[] }) => {
  const { zipFile } = useArticleContext();
  const [image, setImage] = useState<Record<string, string>>({});

  const [hide, setHide] = useState<Record<number, boolean>>(
    chapters.reduce((acc, chapter) => ({
      ...acc,
      [chapter.id]: false
    }), {})
  );

  const toggleChapter = (chapterId: number) => {
    setHide(prev => ({
      ...prev,
      [chapterId]: !prev[chapterId]
    }));
  };

  useEffect(()=>{
    if(typeof window?.MathJax !== "undefined"){
      window.MathJax.typeset();
    }
  },[chapters])

  const extractFilename = (latexString: string): string | null => {
    const match = latexString.match(/\\includegraphics\[.*?\]\{\.\/media\/(.*?)\}/);
    return match ? match[1] : null;
  };

  useEffect(() => {
    const loadImages = async () => {
      if (!zipFile) return;
  
      const zip = await JSZip.loadAsync(zipFile);
      const newCache: Record<string, string> = {};
  
      for (const chapter of chapters) {
        for (const item of chapter.contents) {
          if (item.type === "image") {
            const filename = extractFilename((item as ImageContent).content);
            if (filename && !image[filename]) {
              const file = zip.file(`media/${filename}`);
              if (file) {
                const blob = await file.async("blob");
                newCache[filename] = URL.createObjectURL(blob);
              }
            }
          }
        }
      }
  
      setImage((prev) => ({ ...prev, ...newCache }));
    };
  
    loadImages();
  }, [zipFile, chapters, image]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, paddingBottom: 2 }}>
      {chapters.map((chapter) => (
        <Paper key={chapter.id} sx={{ padding: 1, backgroundColor: "#f5f5f5" }}>
          <Box style={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="h6" gutterBottom sx={{ display: "flex", alignItems: "center", lineHeight: 1, margin: 0, padding: 0 }}>
              {chapter.title}
            </Typography>
            <IconButton onClick={() => toggleChapter(chapter.id)}>{hide[chapter.id] ? <ExpandLess /> : <ExpandMore />}</IconButton>
          </Box>
          {hide[chapter.id] && chapter.contents.map((item, index) => {
            if (item.type === "sentence") {
              if((item as SentenceContent).text.includes("\\\\")){
                const cleanText = (item as SentenceContent).text.replace(/\\/g, "");
                return <Typography key={index} display="inline" variant="body2"><br/>{cleanText}</Typography>;
              } else {
                return <Typography key={index} display="inline" variant="body2">{(item as SentenceContent).text}</Typography>;
              }
            }
            if (item.type === "image") {
              const filename = extractFilename((item as ImageContent).content);
              return (
                <Box key={index} sx={{ textAlign: "center", marginTop: 2, display: "flex", flexDirection: "column", alignItems: "center" }}>
                  {filename && image[filename] ? (
                    <img
                      src={image[filename]}
                      alt={(item as ImageContent).caption || "Image"}
                      style={{ maxWidth: "50%", height: "auto" }}
                    />
                  ) : (
                    <Typography variant="caption">Loading image...</Typography>
                  )}
                  {(item as ImageContent).caption && <Typography variant="caption">{(item as ImageContent).caption}</Typography>}
                </Box>
              );
            }
            if (item.type === "table") {
              return (
                <Box key={index} sx={{ overflowX: "auto", textAlign: "center", mt: 2 }}>
                  {(item as TableContent).caption && <Typography variant="caption">{(item as TableContent).caption}</Typography>}
                  <Typography>Your table is safe, just hiding for now! Check it under the “Preview” button.</Typography>
                </Box>
              );
            }
            return null;
          })}
          {chapter.subChapters && <DisplayChapters chapters={chapter.subChapters} />}
        </Paper>
      ))}
    </Box>
  );
};

export default DisplayChapters;