import React, { FormEvent, useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import Button from '@mui/material/Button';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { Box, Container, Typography } from '@mui/material';
import { useArticleContext } from '../contexts/ArticleContext';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const {
    articleID,
  } = useArticleContext();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      alert("Stripe.js hasn't yet loaded.");
      return;
    }

    const returnUrl = `${window.location.origin}/paymentComplete`;

    // save article id to browser storage
    console.debug('articleID', articleID);
    localStorage.setItem('articleId', articleID);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: returnUrl
      },
    });


    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message || "An error occurred");
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <>
      <Box>
        <Container maxWidth="md">
          <Typography variant="h6">Pay 19.99 PLN to download formatted article</Typography>
          <form onSubmit={handleSubmit}>
            <PaymentElement options={{
              layout: {
                type: 'accordion',
                defaultCollapsed: false,
                radios: true,
                spacedAccordionItems: false
              }
            } as StripePaymentElementOptions} />
            <Box display="flex" justifyContent="center">
              <Button
                type='submit'
                variant="contained"
                color="success"
                sx={{
                  minWidth: 150,
                  textTransform: 'none',
                  backgroundColor: '#069494',
                  borderRadius: 2,
                  marginTop: 2,
                  marginBottom: 2
                }} 
                disabled={!stripe}
              >
                Submit Payment
              </Button>
            </Box>
            {/* Show error message to your customers */}
            {errorMessage && <div>{errorMessage}</div>}
          </form>
        </Container>
      </Box>
    </>
  )
};

export default CheckoutForm;