import React from "react";
import { Box, Container, Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const VerifyEmail = () => {
  return (
    <Container maxWidth="sm">
      <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
        <Typography variant="h4" gutterBottom>
          Verify Email before login
        </Typography>
        <Box mt={2}>
          <Link component={RouterLink} to="/login">
            Go to Login
          </Link>
        </Box>
      </Box>
    </Container>
  );
};

export default VerifyEmail;