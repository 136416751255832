import React, { useMemo, useState } from "react";
import { Paper, Typography, Box, IconButton } from "@mui/material";
import { Chapter, SentenceContent } from "../model/Chapter";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import CitationConverter from "../helpers/CitationConverter";

interface Reference {
  id: string;
  original: string;
  citation: string;
}

const DisplayReferences = ({ chapters }: { chapters: Chapter[] }) => {
  const [isHidden, setIsHidden] = useState<boolean>(true);

  const references = useMemo(() => {
    const extractedReferences: Reference[] = [];
    const seenReferences = new Set<string>();

    chapters.forEach((chapter, chapterIndex) => {
      chapter.contents
        .filter((content) => content.type === "sentence")
        .forEach((content, sentenceIndex) => {
          const sentenceContent = content as SentenceContent;
          
          if (sentenceContent.bibtex) {
            sentenceContent.bibtex.forEach((bibtex, bibtexIndex) => {
              const referenceId = `${chapterIndex}-${sentenceIndex}-${bibtexIndex}`;
              
              if (!seenReferences.has(bibtex)) {
                try {
                  const citation = CitationConverter.toAPA(bibtex);
                  
                  extractedReferences.push({
                    id: referenceId,
                    original: bibtex,
                    citation: citation || 'Unable to parse citation'
                  });

                  seenReferences.add(bibtex);
                } catch (error) {
                  extractedReferences.push({
                    id: referenceId,
                    original: bibtex,
                    citation: 'Error parsing citation'
                  });
                }
              }
            });
          }
        });
    });

    return extractedReferences;
  }, [chapters]);

  const toggleVisibility = () => {
    setIsHidden(!isHidden);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, paddingBottom: 2 }}>
      <Paper sx={{ padding: 1, backgroundColor: "#f5f5f5" }}>
        <Box style={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="h6">References</Typography>
          <IconButton onClick={toggleVisibility}>
            {isHidden ? <ExpandMore /> : <ExpandLess />}
          </IconButton>
        </Box>
        
        {!isHidden && (
          <Box sx={{ mt: 2 }}>
            {references.length === 0 ? (
              <Typography>No references found.</Typography>
            ) : (
              references.map((ref) => (
                <Typography 
                  key={ref.id}
                  sx={{ 
                    mb: 1,
                    color: ref.citation.includes('Error') ? 'error.main' : 'text.primary',
                    fontStyle: ref.citation.includes('Error') ? 'italic' : 'normal'
                  }}
                  variant="body2"
                >
                  {ref.citation}
                </Typography>
              ))
            )}
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default DisplayReferences;