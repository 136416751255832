import React, { useState } from 'react';
import {
  Box,
  Typography,
  Chip,
  Button,
  Dialog,
  DialogContent,
  TextField,
  IconButton,
  Tooltip,
  Stack,
} from '@mui/material';
import {
  Add as AddIcon,
  ErrorOutline as WarningIcon
} from '@mui/icons-material';
import { Author } from '../model/Author';
import { Address } from '../model/Address';
import { useArticleContext } from '../contexts/ArticleContext';

interface AuthorFormData {
  firstName: string;
  lastName: string;
  email: string;
  orchidId: string;
  country: string;
  department: string;
  organization: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  addressCountry: string;
}

const emptyAuthorFormData: AuthorFormData = {
  firstName: '',
  lastName: '',
  email: '',
  orchidId: '',
  country: '',
  department: '',
  organization: '',
  street: '',
  city: '',
  state: '',
  zipCode: '',
  addressCountry: '',
};

const isAuthorIncomplete = (author: Author): boolean => {
  const hasEmptyField = !author.firstName ||
    !author.lastName ||
    !author.email ||
    !author.country ||
    !author.orchidId ||
    !author.affiliation.department ||
    !author.affiliation.organization ||
    !author.affiliation.address.city ||
    !author.affiliation.address.country ||
    !author.affiliation.address.state ||
    !author.affiliation.address.street ||
    !author.affiliation.address.zipCode;

  return hasEmptyField;
};

const AuthorsList = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [editingAuthorId, setEditingAuthorId] = useState<number | null>(null);
  const [formData, setFormData] = useState<AuthorFormData>(emptyAuthorFormData);
  const { article, setArticle } = useArticleContext();

  const handleAddAuthor = () => {
    setEditingAuthorId(null);
    setFormData(emptyAuthorFormData);
    setOpenDialog(true);
  };

  const handleEditAuthor = (author: Author) => {
    setEditingAuthorId(author.id);
    setFormData({
      firstName: author.firstName,
      lastName: author.lastName,
      email: author.email,
      orchidId: author.orchidId,
      country: author.country,
      department: author.affiliation.department,
      organization: author.affiliation.organization,
      street: author.affiliation.address.street,
      city: author.affiliation.address.city,
      state: author.affiliation.address.state,
      zipCode: author.affiliation.address.zipCode,
      addressCountry: author.affiliation.address.country,
    });
    setOpenDialog(true);
  };

  const handleDeleteAuthor = (authorId: number) => {
    if (!article) return;

    const updatedArticle = {
      ...article,
      authors: article.authors.filter((author) => author.id !== authorId),
    };
    setArticle(updatedArticle);
  };

  const handleSubmit = () => {
    if (!article) return;

    const address: Address = {
      street: formData.street,
      city: formData.city,
      state: formData.state,
      zipCode: formData.zipCode,
      country: formData.addressCountry,
    };

    const newAuthor: Author = {
      id: editingAuthorId ?? Math.max(0, ...article.authors.map((a) => a.id)) + 1,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      orchidId: formData.orchidId,
      country: formData.country,
      affiliation: {
        department: formData.department,
        organization: formData.organization,
        address: { ...address },
      },
      correspondingAddress: { ...address },
    };

    const updatedArticle = {
      ...article,
      authors: editingAuthorId
        ? article.authors.map((author) =>
            author.id === editingAuthorId ? newAuthor : author
          )
        : [...article.authors, newAuthor],
    };

    setArticle(updatedArticle);
    setOpenDialog(false);
  };

  return (
    <Box mt={2} mb={2}>
      <Typography variant="body1">Authors:</Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", gap: 1, mt: 2 }}>
        {article?.authors?.length === 0 ? (
          <Tooltip title="Who wrote this masterpiece? 🏆">
            <WarningIcon sx={{ color: "#ff0000 !important" }} />
          </Tooltip>
        ) : (
          article?.authors.map((author) => (
            <Chip
              key={author.id}
              label={`${author.firstName} ${author.lastName}`}
              onClick={() => handleEditAuthor(author)}
              onDelete={() => handleDeleteAuthor(author.id)}
              icon={
                isAuthorIncomplete(author) ? (
                  <Tooltip title="Incomplete author information">
                    <WarningIcon sx={{ color: "#ff0000 !important" }} />
                  </Tooltip>
                ) : undefined
              }
            />
          ))
        )}
        <IconButton
          size="small"
          onClick={handleAddAuthor}
          sx={{ ml: 1, color: "#069494" }}
        >
          <AddIcon />
        </IconButton>
      </Box>
      
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
        <DialogContent>
          <Stack spacing={1}>
            <Typography variant="subtitle1">Author</Typography>

            <TextField label="First Name" size="small" value={formData.firstName} onChange={(e) => setFormData({ ...formData, firstName: e.target.value })} required InputProps={{style: {borderRadius: "7px"}}}/>

            <TextField label="Last Name" size="small" value={formData.lastName} onChange={(e) => setFormData({ ...formData, lastName: e.target.value })} required InputProps={{style: {borderRadius: "7px"}}}/>

            <TextField label="Email" size="small" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} required InputProps={{style: {borderRadius: "7px"}}}/>
            
            <TextField label="ORCID ID" size="small" value={formData.orchidId} onChange={(e) => setFormData({ ...formData, orchidId: e.target.value })} required InputProps={{style: {borderRadius: "7px"}}}/>

            <TextField label="Country" size="small" value={formData.country} onChange={(e) => setFormData({ ...formData, country: e.target.value })} required InputProps={{style: {borderRadius: "7px"}}}/>
            
            <Typography variant="subtitle1" sx={{ mt: 2 }}>Affiliation</Typography>

            <TextField label="Department" size="small" value={formData.department} onChange={(e) => setFormData({ ...formData, department: e.target.value })} required InputProps={{style: {borderRadius: "7px"}}}/>

            <TextField label="Organization" size="small" value={formData.organization} onChange={(e) => setFormData({ ...formData, organization: e.target.value })} required InputProps={{style: {borderRadius: "7px"}}}/>
            
            <Typography variant="subtitle1" sx={{ mt: 2 }}>Address</Typography>

            <TextField label="Street" size="small" value={formData.street} onChange={(e) => setFormData({ ...formData, street: e.target.value })} required InputProps={{style: {borderRadius: "7px"}}}/>

            <TextField label="City" size="small" value={formData.city} onChange={(e) => setFormData({ ...formData, city: e.target.value })} required InputProps={{style: {borderRadius: "7px"}}}/>

            <TextField label="State" size="small" value={formData.state} onChange={(e) => setFormData({ ...formData, state: e.target.value })} required InputProps={{style: {borderRadius: "7px"}}}/>

            <TextField label="Zip Code" size="small" value={formData.zipCode} onChange={(e) => setFormData({ ...formData, zipCode: e.target.value })} required InputProps={{style: {borderRadius: "7px"}}}/>

            <TextField label="Country" size="small" value={formData.addressCountry} onChange={(e) => setFormData({ ...formData, addressCountry: e.target.value })} required InputProps={{style: {borderRadius: "7px"}}}/>
          </Stack>
        </DialogContent>
          <Box display="flex" justifyContent="space-between" marginX={3} marginBottom={2}>
            <Button
              onClick={() => setOpenDialog(false)}
              variant="text"
              sx={{
                textTransform: 'none',
                color: '#bdbdbd',
                fontWeight: "bold"
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: "#069494",
                borderRadius: 2,
                textTransform: 'none',
              }}
            >
              {editingAuthorId ? 'Save Changes' : 'Add Author'}
            </Button>
          </Box>

      </Dialog>
    </Box>
  );
};

export default AuthorsList;
