import React from "react";
import { Container, Grid2, Typography, Card, CardMedia, CardContent } from "@mui/material";

const items = [
  { img: "/word-icon.png", title: "Upload", info: "article in Word.docx" },
  { img: "elsevier-icon.png", title: "Select", info: "any Journal" },
  { img: "/pdf-icon.png", title: "Download", info: "formatted article" }
];

const InformationGraphic = () => {
  return (
    <Container maxWidth="sm" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Grid2 container spacing={3} justifyContent="center">
        {items.map((item, index) => (
          <Grid2 size={{ xs:12, sm: 4 }} key={index}>
            <Card sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: 2, backgroundColor: "transparent", boxShadow: "none" }}>
              <CardMedia component="img" image={item.img} alt={item.title} style={{ width: "auto", maxHeight: 100, margin: "auto" }} />
              <CardContent sx={{ textAlign: "center" }}>
                <Typography variant="body2" fontWeight="bold">{item.title}</Typography>
                <Typography variant="body2" noWrap>{item.info}</Typography>
              </CardContent>
            </Card>
          </Grid2>
        ))}
      </Grid2>
    </Container>
  );
};

export default InformationGraphic;