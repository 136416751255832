import React from "react";
import { Dialog, DialogContent, DialogTitle, DialogContentText, Typography, DialogActions, Button, List, ListItem, ListItemText, Link} from "@mui/material";
  
interface PrivacyPolicyProps {
  isOpen: boolean;
  onClose: () => void;
};

const PrivacyPolicy = ({ isOpen, onClose }: PrivacyPolicyProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Privacy Policy</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1">
            <strong>Background</strong>
          </Typography>
          <Typography variant="body1" marginTop={1} marginBottom={1}>
            <strong>Brainiverse Spółka z ograniczoną odpowiedzialnością</strong> respects the right to privacy of persons whose personal data it has in the collected files, 
            in particular by using appropriate technological and legislative solutions to prevent interference with the privacy of these persons by 
            unauthorized entities.
          </Typography>
          <Typography variant="body1" marginTop={1} marginBottom={1}>
            This Policy provides information about what you can expect when we contact you or you contact us or do business with us. 
          </Typography>
          <Typography variant="body1">
          <strong>By reading the content below you will learn m.in about it:</strong>
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="• why we process your personal data" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• for what purpose do we do it" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• whether the provision of data is mandatory" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• How long we keep your data" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• whether there are other recipients of your personal data" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• what rights do you have" />
            </ListItem>
          </List>
          <Typography variant="body1" marginBottom={1}>
            Our activities a sense of full security and legality of processing at a level appropriate to the personal 
            data protection law in force in Poland, including Regulation 2016/679 of the European Parliament and of 
            the Council of 27 April 2016 on the protection of individuals with regard to the processing related to 
            the collection and processing of all data are aimed at guaranteeing you of personal data and on free movement 
            such data and the repeal of Directive 95/46/EC – <strong>i.e. the so-called GDPR.</strong>
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            We would also like to inform you that as part of our services, there may also, cookies our services be external 
            links enabling direct access to other websites or when using on the device User’s end may be placed 
            in order to enable you to use integrated functionalities (e.g. Facebook, LinkedIn, YouTube). 
            Each provider specifies the rules for the use of cookies in its privacy policy, therefore we have no influence on 
            the privacy policy of the providers and their use of cookies.
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            <strong>Personal Data Controller</strong>
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            The entity deciding on the purposes and methods of using your data (i.e. their administrator in accordance with the provisions of the GDPR) 
            is <strong>Brainiverse Sp. z o.o. with its registered office in Łódź.</strong>
          </Typography>
          <Typography variant="body1">
            There are many ways to contact us, including: 
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="• e-mail: contact@drpaper.ai" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• post office Mazowiecka 3, 92-215 Łódź or" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• contact form." />
            </ListItem>
          </List>
          <Typography variant="body1" marginBottom={1}>
            <strong>Data Protection Officer</strong>
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            Bearing in mind the security and transparency of data processing processes and the need for their constant control, we have appointed a Data Protection Officer (DPO). 
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            You can contact the appointed DPO using …. or via our postal address (please add “Data Protection Officer”)
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            <strong>Safety</strong>
          </Typography>
          <Typography variant="body1">
            We are conscientious about protecting your data and are constantly developing our security systems and processes. The security measures we use are m.in: 
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="• limited physical access to our buildings and user access to our systems – only for those who are authorized" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• controls such as firewall, user verification, strong data encryption, and separation of roles, systems and data" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• Proactive monitoring" />
            </ListItem>
          </List>
          <Typography variant="body1" marginBottom={1}>
            We also use the  highest standards in the industry to support the maintenance of a robust information security management system.
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            <strong>What data do we process?</strong>
          </Typography>
          <Typography variant="body1">
            Most of the personal data we process is transferred directly to us for one of the following reasons:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="• we serve as a processor of the Customer who is the administrator of your data and these data have been provided to us in the scope of cooperation binding us with the Client" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• you cooperate with us or your data has been provided to us by your employer or Partner" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• we have obtained it directly from you or from a third party authorized to provide us with your data" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• you have made us a complaint or inquiry" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• You have made a request for information to us" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• you took part in an event organized by us" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• you represent your organization" />
            </ListItem>
          </List>
          <Typography variant="body1" marginBottom={1}>
            <strong>Basis for processing</strong>
          </Typography>
          <Typography variant="body1">
            In any case, when we process your personal data, we must have the so-called.  ‘legal basis’. The legal bases result directly from the law (GDPR), below are those on which we most often rely:
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="body1">
                    • <strong>Consent:</strong> you have indicated to  us that you consent to the processing of your data for a specific purpose
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="body1">
                    • <strong>Legitimate interests:</strong> only if  the processing is necessary for the conduct of our business and does not interfere with the right to respect for your privacy
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="body1">
                    • <strong>Performance of a contract:</strong> we need to process your personal data in order to be able to provide you with one of our products or services
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="body1">
                    • <strong>Legal claims:</strong> when  the processing of data is necessary for the establishment, exercise or defence of our legal claims
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="body1">
                    • <strong>Legal obligation:</strong> when we are required by law to process your personal data
                  </Typography>
                }
              />
            </ListItem>
          </List>
          <Typography variant="body1" marginBottom={1}>
            <strong>Who can have access to your data? </strong>
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            In addition to us, a number of entities may have access to your data. These may be service providers with whom we work and who help us in our business. 
            These entities process personal data on our behalf and must meet high security standards. We only share information that allows them to provide services 
            to us or facilitate their provision to you.
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            In some circumstances, we may also be  required by law to share information about you – for example, by  a court order, a law or a decision of a public authority. 
            In each case, we make sure that we have a legitimate basis to share information about you and document our decisions.
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            <strong>Transfer of data to the so-called third country</strong>
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            In order to fulfil the purposes of processing described in this policy, we may transfer your personal data to our suppliers, partners or service providers 
            based outside the European Economic Area (EEA). Whenever your data can be found outside the EEA, we will inform you about it.  
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            At the same time, taking care of the security of your data, we strictly comply with the rules of their transfer resulting directly from the GDPR and transfer 
            data only where the degree of security is guaranteed in accordance with the decisions of the European Commission (e.g. to the USA only to entities on the so-called 
              <Link href="https://www.dataprivacyframework.gov/" target="_blank" rel="noopener noreferrer" marginLeft={1}>
                Privacy Shield list
              </Link>
            ). 
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            <strong>How long do we keep your data? </strong>
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            We store your data for the purposes  strictly set out in this privacy policy and in accordance with the law and relevant regulations. We never store personal data 
            longer than necessary. In most cases, the retention period ends 5  years after the end of the relationship with us. However, in some cases, we are obliged to 
            store personal data for up to 10 years from the end of the relationship due to the limitation periods for certain claims.
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            <strong>Is there an obligation to provide data?</strong>
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            As a rule, providing personal data is completely voluntary, however, it may be necessary to achieve specific purposes or your requests, e.g. in the scope of contact, 
            conclusion of a contract, implementation of your rights related to processing, etc. Providing data may also be necessary due to applicable law. 
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            <strong>Your rights related to processing </strong>
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            In accordance with the provisions of personal data protection law, you have specific rights depending on the basis for processing your data.
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            <strong>Right of access</strong>
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            You have the right to know whether and how we use or store your personal data – the so-called right of access. By exercising this right, you can also ask us for a copy of your data.
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            <strong>Right to rectification</strong>
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            You can question the accuracy of the data we process and ask for it to be corrected. This is known as the ‘right to rectification’. If your data is incomplete, you can also ask us to 
            complete it, e.g. by adding new information.
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            <strong>Right to erasure</strong>
          </Typography>
          <Typography variant="body1">
            You can ask us to delete your data and in some circumstances, we need to do so. This right is also known as the so-called “right to be forgotten”. This right is not absolute and applies 
            m.in in the following circumstances where:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="• we no longer need your data" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• Initially, you consented to the processing, but now you have withdrawn it" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• you have objected to the use of your data" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• we have processed your data unlawfully" />
            </ListItem>
          </List>
          <Typography variant="body1">
            We may deny you the right to “be forgotten” in the following circumstances:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="• when we are legally obliged to store your data" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• the storage of data is necessary for the establishment, exercise or defense of legal claims" />
            </ListItem>
          </List>
          <Typography variant="body1" marginBottom={1}>
            <strong>Right to restriction of processing</strong>
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            You have the right to restrict how we use your data, particularly  
            if you are concerned about the accuracy of the data or how it is used. If necessary, you can also request the deletion of certain 
            information about you. This right is closely linked to your rights to challenge the accuracy of your data and to object to its use.
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            <strong>Right to object to processing</strong>
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            Under certain circumstances, you have the right to object to the processing (use) of your data. If your objection is justified, you must 
            stop using your data for the purpose for which it was originally processed, unless we are able to provide reasons justifying further use 
            of your data despite the objections. 
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            You have the absolute right to object to the use of your data for direct marketing purposes. In this case, if you object, we will 
            absolutely stop using your data for this purpose.
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            <strong>Right to data portability</strong>
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            You have the right to obtain your personal data in a way that is accessible and readable on your computer, e.g. as a csv file. You also 
            have the right to ask us to transfer your data to another entity – we will do so if the transfer is, as the GDPR says, “technically feasible”. 
            This right applies only to information that you have provided to us and the processing is carried out in electronic form.
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            <strong>Right to withdraw consent</strong>
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            If your data is processed on the basis  of your  consent, you can additionally exercise the right  to withdraw it at any time – however, 
            this withdrawal does not affect the compliance of the processing before it is made.
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            <strong>Right to lodge a complaint with a supervisory authority</strong>
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            We work to high standards when it comes to processing your personal data.
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            If you have questions or concerns, please contact us and we will answer.
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            If you remain dissatisfied with the content of the answer or the exercise of your right, you can lodge a complaint with the President of the 
            Office for Personal Data Protection about the way we process your personal data.
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            <strong>Exercise of your rights</strong>
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            You can ask for the exercise of your rights orally or in writing. If you give us your request orally, we recommend that you also send it in writing 
            or by email to ensure a clear trace of the correspondence. This will also provide us with clear evidence of your actions and allow us to respond in 
            accordance with the content of your expectations as soon as possible.
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            We do not charge fees for the exercise of your rights and we try to provide information within no more than a month – if it turns out that we need 
            more time, we will inform you about it. 
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            <strong>Cookies</strong>
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            Our services may use cookies constituting IT data, in particular text files that are stored on the end device. Cookies usually contain the name of the 
            website from which they originate, the time of their storage on the end device, and a unique number.
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            The entities placing information in the form of Cookies in the device with which you use the website and obtaining access to them are KPK and its Partners, 
            m.in. providing analytical services, advertisers, or application developers.
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            <strong>Use</strong>
          </Typography>
          <Typography variant="body1">
            Cookies can be used to:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="• provision of maintenance services" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• facilitate the use of the website while browsing it" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• subsequent identification in the event of re-connection of the service with the device on which they were saved;" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• creating statistics that help to understand how users use the website, which allows for improving its structure and content" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• adjusting the content of the website pages to specific preferences and optimizing the use of websites, tailored to individual needs" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• exchange of information with Partners in order to mediate the provision of their services using the website" />
            </ListItem>
          </List>
          <Typography variant="body1" marginBottom={1}>
            <strong>Types of Cookies</strong>
          </Typography>
          <Typography variant="body1">
            As part of the services, we may use the following types of cookies:
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="• “session” – which are stored in the user’s end device until leaving the website or turning off the web browser" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• “persistent” – stored in the User’s end device for the time specified in the parameters of Cookies or until they are deleted by the User " />
            </ListItem>
            <ListItem>
              <ListItemText primary="• “performance” – enabling the collection of information on the use of website pages" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• “functional” – enabling remembering the settings selected by the  user and personalizing the user interface" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• “own” – posted by the website" />
            </ListItem>
            <ListItem>
              <ListItemText primary="• “external” – originating from a website external to the service" />
            </ListItem>
          </List>
          <Typography variant="body1" marginBottom={1}>
            <strong>Manage browser settings</strong>
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            Software for browsing websites, i.e. a web browser, usually allows the storage of cookies on the end device by default.  You can change the settings in this regard.
          </Typography>
          <Typography variant="body1" marginBottom={1}>
            The web browser allows you to delete cookies. It is also possible to automatically block cookies. For details, see the help or documentation of your web browser.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="contained"
          sx={{
            mt: 4,
            mb: 2,
            backgroundColor: "#069494",
            fontWeight: "bold",
            textTransform: 'none',
            fontSize: "18px",
            borderRadius: 2
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PrivacyPolicy;