import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';

const Instruction = () => {
  const [showImage, setShowImage] = useState(false);

  const handleToggle = () => {
    setShowImage((prevState) => !prevState);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={handleToggle}
        sx={{ marginBottom: 2, backgroundColor: '#5BD7D6' }}
      >
        {showImage ? 'Hide Instructions' : 'Show Instructions'}
      </Button>

      {showImage && (
        <Box>
          <Box
            component="img"
            src="/instruction-image.png"
            alt="Instruction"
            sx={{
              maxWidth: '1000px',
              borderRadius: '8px',
              boxShadow: 3,
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default Instruction;
