import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../config/firebaseConfig";
import { Box, Button, Paper, TextField, Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  const handlePasswordReset = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent!");
      setError("");
    } catch (error: any) {
      setError(error.message);
      setMessage("");
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f5f6f6",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 2 }}>
        <Box
            sx={{
                width: 60,
                height: 60,
                borderRadius: "50%",
                backgroundColor: "#ffffff",
                border: "1px solid #069494",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <img src="/logo.svg" alt="DrPaper Logo" width={30} height={30} />
        </Box>
        <Typography variant="h5" fontWeight="bold" mt={2}>
            DrPaper
        </Typography>
        <Typography
            variant="h5"
            fontWeight="bold"
            color="#069494"
            mt={4}
            mb={6}
            sx={{
                textAlign: "center",
                lineHeight: "1.25"
            }}
        >
            Format your article<br />for any Journal in seconds
        </Typography>
    </Box>
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          borderRadius: 2,
          backgroundColor: "#ffffff",
          textAlign: "center",
          minWidth: "400px",
        }}
      >
        <Typography variant="h5" fontWeight="bold">
          Forgot Password
        </Typography>
        <form onSubmit={handlePasswordReset}>
          <TextField
            label="Email"
            type="email"
            fullWidth
            margin="normal"
            size="small"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              style: {
                borderRadius: "7px",
              }
            }}
          />
          {error && <Typography color="error">{error}</Typography>}
          {message && <Typography color="primary">{message}</Typography>}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              backgroundColor: "#069494",
              fontWeight: "bold",
              textTransform: 'none',
              borderRadius: 2
            }}
          >
            Reset Password
          </Button>
        </form>
        <Box mt={2}>
          <Button
            component={RouterLink}
            to="/login"
            variant="text"
            sx={{
              textTransform: 'none',
              color: '#bdbdbd',
              fontWeight: "bold"
            }}
          >
            Back to Login
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default ForgotPassword;