import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, Typography, Box } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

interface InformationDialogProps {
  isDialogOpen: boolean;
  setIsDialogOpen: (isOpen: boolean) => void;
}

const InformationDialog: React.FC<InformationDialogProps> = ({ isDialogOpen, setIsDialogOpen }) => {
  const handleCloseDialog = () => setIsDialogOpen(false);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <Dialog
        open={isDialogOpen}
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            handleCloseDialog();
          }
        }}
        maxWidth="lg"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            maxHeight: "90vh",
            width: "90vw",
          },
        }}
      >
        <DialogContent sx={{ overflow: "auto", maxHeight: "80vh" }}>
          <Typography noWrap sx={{ mb: 2 }}>
            Thank you for using our beta version! 🙌 Before you format your article, please note:
          </Typography>
          <Typography noWrap sx={{ mb: 2 }}>
            1. Use <strong>Mendeley</strong> or <strong>Zotero</strong> to create references (Citavi and EndNote coming soon)
          </Typography>
          <Typography noWrap sx={{ mb: 2 }}>
            2. Use MS Word styles (<strong>Heading 1</strong>, <strong>Heading 2</strong>, etc.) to tag Chapters and Subchapters
          </Typography>
          <Typography noWrap sx={{ mb: 2 }}>
            3. <strong>DO NOT</strong> use List to tag Chapters:
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mb: 2, position: "relative" }}>
            <Box sx={{ position: "relative", width: "40%" }}>
              <img src="/instruction-image2.png" alt="instruction image 2" style={{ width: "100%", height: "auto" }} />
              <ClearIcon 
                sx={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  color: "red",
                  fontSize: 150
                }}
              />
            </Box>
            <Box sx={{ position: "relative", width: "60%" }}>
              <img src="/instruction-image1.png" alt="instruction image 1" style={{ width: "100%", height: "auto" }} />
              <DoneIcon 
                sx={{
                  position: "absolute",
                  top: 10,
                  left: 10,
                  color: "green",
                  fontSize: 150
                }}
              />
            </Box>
          </Box>
          <Typography noWrap sx={{ mb: 2 }}>
            Follow these, and our tool will work like a charm! 🚀
          </Typography>
          <Typography>
            Happy formatting!
          </Typography>
          <Typography>
            The DrPaper Team
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCloseDialog} 
            variant="contained"
            color="primary"
            sx={{
              px: 4,
              backgroundColor: "#069494",
              textTransform: 'none',
              borderRadius: 2
            }}
          >
            I Understand
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InformationDialog;