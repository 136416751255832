import client from '../client';
import { AxiosResponse } from 'axios';
import { getAuth } from 'firebase/auth';
import JSZip from 'jszip';
import { Article } from '../../model/Article';

export const updateZipFile = async (zipFile: File, updatedArticle: Article): Promise<File | null> => {
  if (!zipFile) {
    console.error("No ZIP file found");
    return null;
  }
  try {
    const zip = await JSZip.loadAsync(zipFile);
    zip.file("article.json", JSON.stringify(updatedArticle, null, 2));
    const updatedZipBlob = await zip.generateAsync({ type: "blob" });
    return new File([updatedZipBlob], zipFile.name, { type: "application/zip" });
  } catch (error) {
    console.error("Error updating ZIP file:", error);
    return null;
  }
};

interface PreviewResponse {
  id: string;
  totalPages: number;
}

/**
 * Generates a PDF by sending the article, publisher, journal, and bibliography
 * to the document generation endpoint.
 *
 * @param zipFile - The ZIP file containing the article and additional data.
 * @param publisher - The publisher for which the PDF is being generated.
 * @param journal - The journal for which the PDF is being generated.
 * @param bibliography - The bibliography style to use for citations.
 * @returns A Promise that resolves to a Blob representing the generated PDF.
 */
export const formatDocument = async (
  zipFile: File,
  publisher: string,
  journal: string,
  bibliography: string
): Promise<PreviewResponse> => {
  const endpoint = `/document-with-preview`;

  const formData = new FormData();
  formData.append('file', zipFile, zipFile.name);
  formData.append('publisher', publisher.toLowerCase());
  formData.append('journal', journal.toLowerCase());
  formData.append('bibliography', bibliography.toLowerCase());

  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error("User is not authenticated");
    }

    const token = await currentUser.getIdToken();

    const response: AxiosResponse<PreviewResponse> = await client.post(endpoint, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
      responseType: 'json',
    });

    return response.data;
  } catch (error: any) {
    console.error(`Error during generating Document: ${error.message}`);
    return Promise.reject(error);
  }
};

export const getPreview = async (
  id: string,
  page: number,
): Promise<Blob> => {
  const endpoint = `/resources/preview/${id}/${page}`;

  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error("User is not authenticated");
    }

    const token = await currentUser.getIdToken();

    const response: AxiosResponse<Blob> = await client.get(endpoint, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
      responseType: 'blob',
    });

    return response.data;
  } catch (error: any) {
    console.error(`Error generating preview: ${error.message}`);
    return Promise.reject(error);
  }
};


export const uploadDocument = async (
  id: string
): Promise<Blob> => {
  const endpoint = `/resources/render/${id}`;

  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error("User is not authenticated");
    }

    const token = await currentUser.getIdToken();

    const response: AxiosResponse<Blob> = await client.get(endpoint, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
      responseType: 'blob',
    });

    return response.data;
  } catch (error: any) {
    console.error(`Error generating preview: ${error.message}`);
    return Promise.reject(error);
  }
};