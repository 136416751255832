import { useState } from "react";
import { sendFeedback } from "../../api/endpoints/feedbackEndpoint";
import { Button, TextField, Box, Typography } from "@mui/material";
import { Field, Form, Formik } from "formik";

const FeedbackForm = () => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const validateName = (value: string) => (!value ? "Name is required" : undefined);
  const validateEmail = (value: string) => (!value ? "Your email is required" : undefined);
  const validateContent = (value: string) => (!value ? "Your feedback is required" : undefined);

  return (
    <Formik
      initialValues={{ name: "", email: "", content: "" }}
      onSubmit={(values: any, actions: any) => {
        const preprocessedContent = `From: ${values.name} <${values.email}>\n\n${values.content}`;
        sendFeedback(values.name, values.email, "DrPaper Feedback", preprocessedContent)
          .then(() => {
            setSuccessMessage("Your feedback has been sent successfully!");
            actions.resetForm();
            setTimeout(() => setSuccessMessage(null), 10000);
          })
          .catch(() => {
            setSuccessMessage("Something went wrong. Please try again.");
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <Box display="flex" flexDirection="column" gap={2} sx={{ alignItems: "center", mt: 2 }}>
            <Field name="name" validate={validateName}>
              {({ field }: any) => (
                <TextField
                  {...field}
                  label="Name"
                  variant="outlined"
                  error={Boolean(errors.name && touched.name)}
                  helperText={touched.name && errors.name}
                  fullWidth
                />
              )}
            </Field>

            <Field name="email" validate={validateEmail}>
              {({ field }: any) => (
                <TextField
                  {...field}
                  label="Your email"
                  type="email"
                  variant="outlined"
                  error={Boolean(errors.email && touched.email)}
                  helperText={touched.email && errors.email}
                  fullWidth
                />
              )}
            </Field>

            <Field name="content" validate={validateContent}>
              {({ field }: any) => (
                <TextField
                  {...field}
                  label="Your feedback"
                  variant="outlined"
                  multiline
                  rows={4}
                  error={Boolean(errors.content && touched.content)}
                  helperText={touched.content && errors.content}
                  fullWidth
                />
              )}
            </Field>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
              fullWidth
              sx={{
                backgroundColor: "#069494",
                fontWeight: "bold",
                textTransform: 'none',
                borderRadius: 2
              }}
            >
              {isSubmitting ? "Sending..." : "Submit"}
            </Button>
            {successMessage && (
            <Typography color={successMessage.includes("successfully") ? "green" : "red"} sx={{ mt: 2 }}>
              {successMessage}
            </Typography>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default FeedbackForm;