import { Elements } from '@stripe/react-stripe-js';
import { StripeElementLocale } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { useEffect } from 'react';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);

interface CheckoutProps {
    clientSecret: string;
}

const Checkout = ({clientSecret}: CheckoutProps) => {
    useEffect(() => {
        if (!stripePromise) {
            throw new Error("Stripe promise is not loaded");
        }

        if (clientSecret === undefined) {
            throw new Error("clientSecret is not passed");
        }
    })
    const options = {
        clientSecret: clientSecret,
        locale: 'en' as StripeElementLocale,
    };

    return (
        <Elements stripe={stripePromise} options={options} >
            <CheckoutForm />
        </Elements >
    );
};

export default Checkout;