import client from '../client';
import { Article } from '../../model/Article';
import JSZip from 'jszip';
import { useArticleContext } from '../../contexts/ArticleContext';
import { getAuth } from '@firebase/auth';

/**
 * Convert docx to article with ZIP context
 *
 * @param file - The file to be converted to article model.
 * @returns A promise that ends with obtaining the structure of the Article.
 */
export const useConvertDocxToArticle = () => {
  const { setZipFile } = useArticleContext();

  const convertDocxToArticle = async (file: File): Promise<Article> => {
    const endpoint = `/convert`;

    const formData = new FormData();
    formData.append('file', file);

    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error("User is not authenticated");
      }

      const token = await currentUser.getIdToken();

      const response = await client.post(endpoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
        responseType: 'arraybuffer',
      });

      const zip = await JSZip.loadAsync(response.data);

      const zipBlob = new Blob([response.data], { type: 'application/zip' });
      const zipFile = new File([zipBlob], 'converted-article.zip', { type: 'application/zip' });
      setZipFile(zipFile);

      const articleJson = await zip.file('article.json')?.async('string');
      if (!articleJson) {
        throw new Error("article.json not found in the ZIP response");
      }

      const article: Article = JSON.parse(articleJson);

      return article;
    } catch (error: any) {
      if (error.response) {
        let errorMessage;
        if (error.response.data instanceof ArrayBuffer) {
          const decoder = new TextDecoder('utf-8');
          errorMessage = decoder.decode(error.response.data);
        } else {
          errorMessage = error.response.data;
        }
        return Promise.reject(errorMessage);
      } else {
        return Promise.reject('An unexpected error occurred.');
      }
    }
  };

  return convertDocxToArticle;
};