import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import { generatePdf } from '../api/endpoints/generatePdfEndpoint';
import { Box, Button, CircularProgress, Typography, Alert, AlertTitle } from '@mui/material';
import { useArticleContext } from '../contexts/ArticleContext';

const DownloadPreparedArticle = () => {
	const {
		zipFile,
		publisher,
		journal,
		bibliography,
		article,
	} = useArticleContext();
	const [loading, setLoading] = useState(false);
	const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
    setPdfBlob(null);
  }, [publisher, journal, bibliography]);

	const handleGeneratePdf = async () => {
		setLoading(true);
		setPdfBlob(null);
		setError(null);

		if (!zipFile) {
			setError('ZIP file is missing. Please upload and process the article first.');
			setLoading(false);
			return;
		}

		try {
			const pdfBlob = await generatePdf(zipFile, publisher, journal, bibliography);
			console.log('PDF generated successfully');
			setPdfBlob(pdfBlob);
		} catch (error) {
			console.error('Failed to generate PDF:', error);
			setError('An error occurred while generating the article. Please try again.');
		} finally {
			setLoading(false);
		}
	};

	const handleDownloadPdf = () => {
		if (pdfBlob) {
			const pdfUrl = window.URL.createObjectURL(pdfBlob);
			saveAs(pdfBlob, `${article?.title}.pdf`);
			window.URL.revokeObjectURL(pdfUrl);
		}
	};

	return (
		<Box display="flex" flexDirection="column" alignItems="center" gap={2}>
			{!pdfBlob ? (
				<Button
					onClick={handleGeneratePdf}
					variant="contained"
					color="primary"
					disabled={loading}
					startIcon={loading && <CircularProgress size={20} color="inherit" />}
					sx={{ minWidth: 150 }}
				>
					{loading ? 'Formatting Article...' : 'Format Article'}
				</Button>
			) : (
				<Button
					onClick={handleDownloadPdf}
					variant="contained"
					color="success"
					sx={{ minWidth: 150 }}
				>
					Download PDF
				</Button>
			)}
			{loading && (
				<Typography variant="body2" color="textSecondary">
					Please wait while the PDF is being generated.
				</Typography>
			)}
			{error && (
				<Alert severity="error" sx={{ width: '100%', mt: 2 }}>
					<AlertTitle>Error</AlertTitle>
					{error}
				</Alert>
			)}
		</Box>
	);
};

export default DownloadPreparedArticle;