import React, { useState } from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { useErrorContext } from '../contexts/ErrorContext';
import Instruction from './Instruction';

const ErrorWithInstruction = () => {
  const { instructionError, hideInstructionError } = useErrorContext();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  if (!instructionError) return null;
  return (
    <>
      <Alert severity="error" sx={{ width: '100%', mt: 2 }} onClose={hideInstructionError}>
        <AlertTitle>Error</AlertTitle>
        Your file requires adjustments, please check the below warnings and use{' '}
        <a
          href="#"
          onClick={handleOpenModal}
          style={{
            color: '#1976d2',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
        >
          instructions
        </a>
        {' '}to prepare your document.
      </Alert>
      <Instruction open={isModalOpen} onClose={handleCloseModal} />
    </>
  );
};

export default ErrorWithInstruction;