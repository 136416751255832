import React, {useState} from "react";
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import {initializeApp} from "firebase/app";
import {useNavigate, Link as RouterLink} from "react-router-dom";
import {firebaseConfig} from "../config/firebaseConfig";
import {
    Box,
    Button,
    TextField,
    Typography,
    Checkbox,
    Divider,
    Paper,
    IconButton,
    InputAdornment
} from "@mui/material";
import {useMixpanel} from "../contexts/MixpanelContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import GoogleLoginWithTerms from "../components/GoogleLoginWithTerms";
import InformationGraphic from "../components/InformationGraphic";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const mixpanel = useMixpanel();
    const eventName = "fe_login";

    const handleTogglePassword = () => {
        setShowPassword((prev) => !prev);
    };

    const handleLogin = async (e: React.FormEvent) => {
        mixpanel.startTimeMeasurement(eventName);
        e.preventDefault();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            
            // Store email
            localStorage.setItem("email", email);
            
            // Handle user's name data for existing users
            if (user.displayName) {
                // Try to parse the displayName into first and last name
                const nameParts = user.displayName.split(' ');
                if (nameParts.length >= 2) {
                    const firstName = nameParts[0];
                    const lastName = nameParts.slice(1).join(' ');
                    
                    localStorage.setItem("firstName", firstName);
                    localStorage.setItem("lastName", lastName);
                } else {
                    // If only one name is available, store it as firstName
                    localStorage.setItem("firstName", user.displayName);
                    localStorage.setItem("lastName", "");
                }
            } else {
                // No displayName available
                localStorage.setItem("firstName", "");
                localStorage.setItem("lastName", "");
                
                // Optional: You could redirect to a profile completion page
                // navigate('/complete-profile');
            }
            
            mixpanel.trackEvent(eventName, {success: true, method: "email"});
            navigate("/"); // Redirect to the main page after successful login
        } catch (error: any) {
            mixpanel.trackEvent(eventName, {success: false, method: "email"});
            setError(error.message);
        }
    };

    return (
        <Box
            sx={{
                backgroundColor: "#f5f6f6",
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: 2
            }}
        >
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Box
                    sx={{
                        width: 60,
                        height: 60,
                        borderRadius: "50%",
                        backgroundColor: "#ffffff",
                        border: "1px solid #069494",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <img src="/logo.svg" alt="DrPaper Logo" width={30} height={30} />
                </Box>
                <Typography variant="h5" fontWeight="bold" mt={2}>
                    DrPaper
                </Typography>
                <Typography
                    variant="h5"
                    fontWeight="bold"
                    color="#069494"
                    mt={4}
                    sx={{
                        textAlign: "center",
                        lineHeight: "1.25"
                    }}
                >
                    Format your article<br />for any Journal in seconds
                </Typography>
            </Box>

            <InformationGraphic />

            <Paper
                elevation={3}
                sx={{
                    padding: 4,
                    borderRadius: 2,
                    backgroundColor: "#ffffff",
                    width: "100%",
                    maxWidth: 400,
                    textAlign: "center"
                }}
            >
                <Typography variant="h6" fontWeight="bold" mb={5} mt={2}>
                    Welcome back!
                </Typography>

                <GoogleLoginWithTerms onError={setError}/>

                <Divider sx={{ width: "100%", my: 5 }}>or</Divider>


                <form onSubmit={handleLogin} style={{ width: "100%" }}>
                    <TextField
                        label="Email"
                        type="email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        size="small"
                        sx={{ mb: 2 }}
                        InputProps={{
                            style: {
                                borderRadius: "7px",
                            }
                        }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        size="small"
                        sx={{ mt:2 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={handleTogglePassword} edge="end">
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                            ),
                            style: {
                                borderRadius: "7px",
                            }
                        }}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                        <Box display="flex" alignItems="center">
                            <Checkbox size="medium" sx={{ color: "#069494", '&.Mui-checked': { color: "#069494" } }} />
                            <Typography variant="body2" color="grey">Remember me</Typography>
                        </Box>
                        <Typography
                            variant="body2"
                            color="grey"
                            sx={{
                                cursor: "pointer",
                                textDecoration: "none"
                            }}
                            component={RouterLink}
                            to="/forgot-password"
                        >
                            Forgot password
                        </Typography>
                    </Box>
                    {error && <Typography color="error">{error}</Typography>}
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        sx={{
                            mt: 4,
                            mb: 2,
                            backgroundColor: "#069494",
                            maxWidth: "50%",
                            mx: "auto",
                            fontWeight: "bold",
                            textTransform: 'none',
                            fontSize: "18px",
                            borderRadius: 2
                        }}
                    >
                        Log in
                    </Button>
                </form>
                <Typography variant="h6" fontWeight="bold" mt={2}>
                    Don't have an account? {" "}
                    <Typography
                        component={RouterLink}
                        to="/register"
                        variant="h6"
                        fontWeight="bold"
                        color="#069494"
                        sx={{
                            cursor: "pointer",
                            textDecoration: "none"
                        }}
                    >
                        Sign up
                    </Typography>
                </Typography>
            </Paper>
        </Box>
    );
};

export default Login;