import React, { useEffect, useState } from 'react';
import { uploadDocument } from '../api/endpoints/generateDocumentOrPreview';
import { Button, Snackbar, Alert } from '@mui/material';
import { saveAs } from 'file-saver';
import {useMixpanel} from '../contexts/MixpanelContext';
import { useArticleContext } from '../contexts/ArticleContext'; // temporary added

const DownloadArticleButton = () => {
  const mixpanel = useMixpanel();
  const [articleId, setArticleId] = useState<string>("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<'error' | 'warning' | 'info' | 'success'>('error');
  const { articleID } = useArticleContext(); // temporary added

  useEffect(() => {
    //const articleId = localStorage.getItem('articleId') || ""; // temporary commented
    setArticleId(articleID); // setArticleId(articleId); // temporary commented
  }, [articleId]);

  const handleDownloadDocument = async () => {
    if (!articleId) {
      console.error('ArticleID is missing:', articleId);
      setAlertMessage("Internal error. Please contact support.");
      setAlertSeverity('error');
      setAlertOpen(true);
      return;
    }

    const eventName = "fe_download_document";
    mixpanel.startTimeMeasurement(eventName);

    try {
      const pdfBlob = await uploadDocument(articleId);
      if (pdfBlob) {
        const pdfUrl = window.URL.createObjectURL(pdfBlob);
        saveAs(pdfBlob, `${articleId}.pdf`);
        window.URL.revokeObjectURL(pdfUrl);
      }
      mixpanel.trackEvent(eventName,{success: true});
    } catch (error) {
      mixpanel.trackEvent(eventName,{success: false});
      console.error('Failed to format document:', error);
      setAlertMessage("Failed to download the document. Please try again.");
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleDownloadDocument}
        variant="contained"
        color="success"
        sx={{ minWidth: 150, textTransform: 'none', backgroundColor: "#069494", borderRadius: 2 }}
      >
        Download Your Article
      </Button>
      
      <Snackbar 
        open={alertOpen} 
        autoHideDuration={6000} 
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseAlert} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default DownloadArticleButton;