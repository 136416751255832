import { Cite } from "@citation-js/core";
import "@citation-js/plugin-bibtex";
import "@citation-js/plugin-csl";

interface CitationOptions {
  outputStyle?: string;
  language?: string;
}

class CitationConverter {
  /**
   * Convert citation to APA style
   * @param citation Raw citation data
   * @param options Conversion options
   * @returns APA style formatted citation
   */
  static toAPA(citation: string, options: CitationOptions = {}): string {
    try {
      const cite = new Cite(citation);
      return cite.format("bibliography", {
        format: "text",
        template: options.outputStyle || "apa",
        lang: options.language || "en-US",
      }).trim();
    } catch (error) {
      return "Error: Unable to parse citation";
    }
  }

  /**
   * Convert multiple citations to APA style
   * @param citations Array of citation strings
   * @param options Conversion options
   * @returns Array of APA style citations
   */
  static toAPAMultiple(citations: string[], options: CitationOptions = {}): string[] {
    return citations.map((citation) => this.toAPA(citation, options));
  }
}

export default CitationConverter;