import React, { useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Link } from "@mui/material";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../config/firebaseConfig";
import { useNavigate } from "react-router-dom";
import PrivacyPolicy from "./PrivacyPolicy";
import {useMixpanel} from "../contexts/MixpanelContext";
import { FcGoogle } from "react-icons/fc";

interface GoogleLoginWithTermsProps {
  onError: (message: string) => void;
}

const GoogleLoginWithTerms = ({onError}: GoogleLoginWithTermsProps) => {
  const [openTermsDialog, setOpenTermsDialog] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const googleProvider = new GoogleAuthProvider();
  const mixpanel = useMixpanel();
  const eventName = "fe_register";
  const navigate = useNavigate();

  const handleGoogleLogin = async () => {
    const hasAcceptedTerms = localStorage.getItem("acceptedTerms");
    if (hasAcceptedTerms) {
      await signInWithGoogle();
    } else {
      setOpenTermsDialog(true);
    }
  };

  const handleAcceptTerms = async () => {
    setOpenTermsDialog(false);
    localStorage.setItem("acceptedTerms", "true");
    await signInWithGoogle();
  };

  const signInWithGoogle = async () => {
    mixpanel.startTimeMeasurement(eventName);
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      if (user.email) { 
        localStorage.setItem("email", user.email);
      }
      mixpanel.trackEvent(eventName, {success: true, method: "google"});
      navigate("/"); // Redirect to the main page after successful login
    } catch (error: any) {
      mixpanel.trackEvent(eventName, {success: false, method: "google"});
      onError(error.message);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        fullWidth
        startIcon={<FcGoogle />} 
        sx={{
            mb: 2,
            borderColor: "rgba(0, 0, 0, 0.10)",
            color: "black",
            backgroundColor: "white",
            textTransform: 'none',
            borderRadius: 2
        }} 
        onClick={handleGoogleLogin}
      >
        Continue with Google
      </Button>

      <Dialog open={openTermsDialog} onClose={() => setOpenTermsDialog(false)} maxWidth="xs" fullWidth>
        <DialogTitle>Accept Terms of Service</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Have you read and accepted{" "}
            <Link onClick={() => setIsModalOpen(true)} style={{ cursor: "pointer", textDecoration: "underline" }}>
              DrPaper's Terms of Service
            </Link>
            ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            fullWidth
            variant="text"
            sx={{
              textTransform: 'none',
              color: '#bdbdbd',
              fontWeight: "bold"
            }}
            onClick={() => setOpenTermsDialog(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              backgroundColor: "#069494",
              fontWeight: "bold",
              textTransform: 'none',
              borderRadius: 2
            }}
            onClick={handleAcceptTerms}
          >
            Accept & Continue
          </Button>
        </DialogActions>
      </Dialog>

      <PrivacyPolicy isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
};

export default GoogleLoginWithTerms;
