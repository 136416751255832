import React, { useEffect } from 'react';
import { Box, TextField, Chip } from '@mui/material';
import { useArticleContext } from '../contexts/ArticleContext';
import { Chapter, SentenceContent } from '../model/Chapter';
import { Article } from '../model/Article';
import { useErrorContext } from '../contexts/ErrorContext';
import AuthorsList from './AuthorsList';
import DisplayChapters from './DisplayChapters';
import DisplayReferences from './DisplayReferences';

const ArticleDetails = () => {
  const { article, setArticle } = useArticleContext();
  const { showInstructionError, hideInstructionError } = useErrorContext();

  useEffect(() => {
    if (
      article &&
      (!article.title ||
      !article.authors?.length ||
      !article.abstract ||
      article.keywords?.length === 0 ||
      !article.chapters ||
      countContentElements(article.chapters).referenceCount === 0)) {
        showInstructionError();
      } else {
        hideInstructionError();
      }
  }, [article, hideInstructionError, showInstructionError]);

  if (!article) return null;

  const handleUpdate = <K extends keyof Article>(field: K, value: Article[K]) => {
    setArticle(article ? { ...article, [field]: value } : article);
  };

  return (
    <Box mt={2}>

      <TextField
        label="Title"
        variant="outlined"
        size="small"
        fullWidth
        value={article.title || ""}
        onChange={(e) => handleUpdate("title", e.target.value)}
        error={!article.title}
        helperText={!article.title ? "Title is required" : ""}
        margin="normal"
        InputProps={{
          style: {
              borderRadius: "7px",
          }
        }}
      />

      <TextField
        label="Abstract"
        variant="outlined"
        size="small"
        fullWidth
        multiline
        rows={6}
        value={article.abstract || ""}
        onChange={(e) => handleUpdate("abstract", e.target.value)}
        error={!article.abstract}
        helperText={!article.title ? "Abstract is required" : ""}
        margin="normal"
        InputProps={{
          style: {
              borderRadius: "7px",
          }
        }}
      />
      
      <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
        <TextField
          label="Add Keyword"
          variant="outlined"
          size="small"
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              const inputValue = (e.target as HTMLInputElement).value.trim();
              if (inputValue) {
                handleUpdate("keywords", [...(article.keywords || []), inputValue]);
                (e.target as HTMLInputElement).value = "";
              }
            }
          }}
          error={!article.keywords?.length}
          helperText={!article.keywords?.length ? "At least one keyword is required" : ""}
          InputProps={{
            style: {
                borderRadius: "7px",
            }
          }}
        />
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
        {article.keywords?.map((keyword, index) => (
          <Chip key={index} label={keyword} onDelete={() => handleUpdate("keywords", article.keywords.filter((_, i) => i !== index))} />
        ))}
      </Box>

      <AuthorsList />

      <DisplayChapters chapters={article.chapters} />

      <DisplayReferences chapters={article.chapters} />
      
    </Box>
  );
};

type ContentElementCounts = {
  imageCount: number;
  tableCount: number;
  referenceCount: number;
};

const countContentElements = (chapters: Chapter[]): ContentElementCounts => {
  let counts: ContentElementCounts = { imageCount: 0, tableCount: 0, referenceCount: 0 };
  chapters.forEach(({ contents, subChapters }) => {
    contents.forEach((content) => {
      if (content.type === 'image') counts.imageCount++;
      if (content.type === 'table') counts.tableCount++;
      if (content.type === 'sentence' && (content as SentenceContent).bibtex?.length) counts.referenceCount++;
    });
    if (subChapters?.length) {
      const subCounts = countContentElements(subChapters);
      counts.imageCount += subCounts.imageCount;
      counts.tableCount += subCounts.tableCount;
      counts.referenceCount += subCounts.referenceCount;
    }
  });
  return counts;
};

export default ArticleDetails;