import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const Feedback = () => {
  const handleButtonClick = () => {
    window.open('https://drpaper.ai/journal-format/#format-signup', '_blank');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 3,
        margin: '0 auto',
      }}
    >
      <Typography variant="h6" component="div" gutterBottom>
        This app is just the beginning! 
      </Typography>
      <Typography component="div" gutterBottom>
        It’s transforming into a powerful Scientific Collaboration Platform.
      </Typography>
      <Typography component="div" gutterBottom>
        Join the next-generation Scientific Community and get exclusive updates.
      </Typography>
      <Button
        variant="contained"
        onClick={handleButtonClick}
        sx={{
          backgroundColor: '#5BD7D6'
        }}
      >
        Sign Up Here
      </Button>
    </Box>
  );
};

export default Feedback;