import { useState, useEffect } from "react";
import PayButton from "./PayButton";
import DownloadArticleButton from "./DownloadArticleButton";
import { Typography } from "@mui/material";
import emailList from "../data/emails.json";

const PayOrDownloadTemporary = () => {
  const [isEmailAllowed, setIsEmailAllowed] = useState<boolean | null>(null);

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");

    if (storedEmail && emailList.includes(storedEmail)) {
      setIsEmailAllowed(true);
    } else {
      setIsEmailAllowed(false);
    }
  }, []);

  if (isEmailAllowed === null) {
    return <Typography>Loading...</Typography>;
  }

  return isEmailAllowed ? <DownloadArticleButton /> : <PayButton />;
};

export default PayOrDownloadTemporary;