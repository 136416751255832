import React, { useState, useEffect, useCallback } from 'react';
import { formatDocument, updateZipFile } from '../api/endpoints/generateDocumentOrPreview';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useArticleContext } from '../contexts/ArticleContext';
import { useErrorContext } from '../contexts/ErrorContext';
import Preview from './Preview';
import {useMixpanel} from "../contexts/MixpanelContext";
import { Author } from '../model/Author';

const PrepareArticle = () => {
	const {
		articleID,
		setArticleID,
		totalPages,
		setTotalPages,
		zipFile,
		publisher,
		journal,
		bibliography,
		article,
	} = useArticleContext();
	const [loading, setLoading] = useState(false);
	const { setGeneralError, showInstructionError, hideInstructionError } = useErrorContext();
	const mixpanel = useMixpanel();

	const isAuthorComplete = useCallback((obj: Author): boolean => {
		return Object.values(obj).every(value => {
			if (typeof value === "object" && value !== null) {
				return isAuthorComplete(value);
			}
			return value !== "" && value !== null && value !== undefined;
		});
	}, []);

	useEffect(() => {
    setArticleID('');
  }, [publisher, journal, bibliography, setArticleID]);

	useEffect(() => {
		if(publisher && (journal || bibliography) && article && !article.authors.some(author => !isAuthorComplete(author))) {
			setGeneralError(null);
		}
	}, [publisher, journal, bibliography, article, setGeneralError, isAuthorComplete]);

	useEffect(() => {
		if(article && zipFile) {
			hideInstructionError();
		}
	}, [article, zipFile, hideInstructionError]);

	const validateInputs = (): boolean => {
    if (!publisher || (!journal && !bibliography) || !article) {
      setGeneralError(
        'Before Formatting: Select Publisher, Journal, or Reference Format and Upload article file.'
      );
      return false;
    }
    return true;
  };

	const handleGenerateDocument = async () => {
		if (!validateInputs()) return;
        
		const eventName = "fe_format_document";
		mixpanel.startTimeMeasurement(eventName);

		setLoading(true);
		setArticleID('');

		if (!article || !zipFile) {
			showInstructionError();
			setLoading(false);
			return;
		}

		if(article.authors.some(author => !isAuthorComplete(author))) {
			setGeneralError(
        'Complete all Author information to keep things moving!'
      );
			setLoading(false);
			return;
		}

		const updatedZip = await updateZipFile(zipFile, article);
  	if (!updatedZip) return;


		try {
			const {id, totalPages } = await formatDocument(updatedZip, publisher, journal, bibliography);
			setArticleID(id);
			setTotalPages(totalPages);
			mixpanel.trackEvent(eventName,{success: true});
		} catch (error) {
			mixpanel.trackEvent(eventName,{success: false});
			console.error('Failed to format document:', error);
			showInstructionError();
			return;
		} finally {
			setLoading(false);
		}
	};

	return (
		<Box display="flex" flexDirection="column" alignItems="center" gap={2}>
			{!articleID ? (
				<Button
					onClick={handleGenerateDocument}
					variant="contained"
					color="primary"
					disabled={loading}
					startIcon={loading && <CircularProgress size={20} color="inherit" />}
					sx={{ minWidth: 150, textTransform: 'none', backgroundColor: "#069494", borderRadius: 2 }}
				>
					{loading ? 'Formatting Article...' : 'Format Article'}
				</Button>
			) : (
				<>
					<Preview id={articleID} pages={totalPages}/>
				</>
			)}
			{loading && (
				<Typography variant="body2" color="textSecondary">
					Please wait while the Document is being generated.
				</Typography>
			)}
		</Box>
	);
};

export default PrepareArticle;