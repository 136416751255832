import React, { useEffect, useState } from 'react';
import { getBibliography, getJournalsByPublisher } from '../api/endpoints/PublishersJournalsBibliography';
import { Box, MenuItem, FormControl, Select, InputLabel, SelectChangeEvent, CircularProgress, Typography } from '@mui/material';
import { useArticleContext } from '../contexts/ArticleContext';

const JournalOrBibliographyDropDown = () => {
	const {
    publisher,
    setJournal,
    setBibliography,
  } = useArticleContext();
	const [allOptions, setAllOptions] = useState<string[]>([]);
  const [displayedOptions, setDisplayedOptions] = useState<string[]>([]);
	const [selectedOption, setSelectedOption] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);

	const CHUNK_SIZE = 50;

	useEffect(() => {
		const fetchOptions = async () => {
			setLoading(true);
			try {
				let options: string[] = [];
				if (publisher.toLowerCase() === 'generic') {
					const bibliographyStyles = await getBibliography();
					options = Object.values(bibliographyStyles).flat();
				} else {
					options = await getJournalsByPublisher(publisher);
				}
				setAllOptions(options);
        setDisplayedOptions(options.slice(0, CHUNK_SIZE));
			} catch (error) {
				console.error(`Failed to fetch options for publisher '${publisher}':`, error);
			} finally {
        setLoading(false);
      }
		};

		if (publisher) {
			setAllOptions([]);
      setDisplayedOptions([]);
      fetchOptions();
		}
	}, [publisher]);

	const handleScroll = (event: React.UIEvent<HTMLUListElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    if (scrollHeight - scrollTop <= clientHeight + 50 && displayedOptions.length < allOptions.length) {
      const nextChunk = allOptions.slice(
        displayedOptions.length,
        displayedOptions.length + CHUNK_SIZE
      );
      setDisplayedOptions((prevOptions) => [...prevOptions, ...nextChunk]);
    }
  };

	const handleSelectChange = (event: SelectChangeEvent<string>) => {
		const selectedValue = event.target.value;
		setSelectedOption(selectedValue);

		if (publisher.toLowerCase() === 'generic') {
			setBibliography(selectedValue);
		} else {
			setJournal(selectedValue);
		}
	};

	return (
		<Box display="flex" flexDirection="column" gap={2}>
			<FormControl variant="outlined" disabled={!publisher} size="small">
				<InputLabel id="options-dropdown-label">
					{publisher.toLowerCase() === 'generic' ? 'Select Reference Format' : 'Select Journal'}
				</InputLabel>
				<Select
					labelId="options-dropdown-label"
					id="options-dropdown"
					value={selectedOption}
					onChange={handleSelectChange}
					label={publisher.toLowerCase() === 'generic' ? 'Select Reference Format' : 'Select Journal'}
					MenuProps={{
            PaperProps: {
              style: { maxHeight: 300, overflow: 'auto' },
              onScroll: handleScroll,
            },
          }}
				>
					<MenuItem value="" disabled>
						{publisher.toLowerCase() === 'generic' ? 'Select Reference Format' : 'Select Journal'}
					</MenuItem>
					{displayedOptions.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
          {loading && (
            <Box display="flex" justifyContent="center" alignItems="center" padding={1}>
              <CircularProgress size={20} />
            </Box>
          )}
          {!loading && allOptions.length === 0 && (
            <Typography textAlign="center" variant="body2" padding={1}>
              No options available
            </Typography>
          )}
				</Select>
			</FormControl>
		</Box>
	);
};

export default JournalOrBibliographyDropDown;