import client from '../client';
import { AxiosResponse } from 'axios';

export const getPublishers = (): Promise<string[]> => {
  const endpoint = `/publishers`;
  return client
    .get<string[]>(endpoint)
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Failed to fetch publishers: ${error.message}`);
      return Promise.reject(error);
    });
};

export const getJournalsByPublisher = (publisher: string): Promise<string[]> => {
  const endpoint = `/publishers/${publisher}/journals`;
  return client
    .get<string[]>(endpoint)
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Failed to fetch journals for publisher '${publisher}': ${error.message}`);
      return Promise.reject(error);
    });
};

export const getBibliography = (): Promise<Map<string, string[]>> => {
  const endpoint = `/publishers/bibliography`;
  return client
    .get(endpoint)
    .then((response: AxiosResponse<Map<string, string[]>>) => {
			let styles: Map<string, string[]> = response.data;
			return styles;
		})
    .catch((error) => {
      console.error(`Failed to fetch bibliography styles: ${error.message}`);
      return Promise.reject(error);
    });
};