import React from 'react';
import MainPage from './pages/MainPage';
import Login from './pages/Login';
import { Route, Routes } from 'react-router-dom';
import Register from './pages/Register';
import ProtectedRoute from './components/ProtectedRoute';
import NotFound from './pages/NotFound';
import VerifyEmail from './pages/VerifyEmail';
import ForgotPassword from './pages/ForgotPassword';
import { MixpanelProvider } from './contexts/MixpanelContext';
import PaymentComplete from './pages/PaymentComplete';

function App() {
    return (
        <MixpanelProvider>
            <Routes>
                <Route path="/" element={
                    <ProtectedRoute>
                        <MainPage />
                    </ProtectedRoute>
                } />
                <Route path="/paymentComplete" element={
                    <ProtectedRoute>
                        <PaymentComplete />
                    </ProtectedRoute>
                } />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/verify-email" element={<VerifyEmail />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </MixpanelProvider>
    );
}

export default App;
