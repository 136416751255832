import React, { useState } from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import DocxReader from "./DocxReader";
import PrepareArticle from "./PrepareArticle";
import JournalOrBibliographyDropDown from "./JournalOrBibliographyDropDown";
import PublishersDropDown from "./PublishersDropDown";
import Instruction from "./Instruction";
import ArticleDetails from "./ArticleDetails";
import ErrorWithInstruction from "./ErrorWithInstruction";
import GeneralError from "./GeneralError";
import DescriptionIcon from "@mui/icons-material/Description";

const ArticleFormatter = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    return (
        <Box>
            <Container maxWidth="md" sx={{ mt: 4 }}>
                <Typography variant="h4" gutterBottom align="center" fontWeight="bold" color="#069494">
                    Format your article for any Journal in seconds
                </Typography>
                <PublishersDropDown />
                <Box sx={{ mt: 2 }}>
                    <JournalOrBibliographyDropDown />
                </Box>
                <Box display="flex" alignItems="center" justifyContent="center" gap={2} marginTop={2}>
                    <Typography 
                        variant="h6" 
                        gutterBottom 
                        marginTop={2}
                        sx={{ display: "flex", alignItems: "center", lineHeight: 1, margin: 0, padding: 0 }}
                    >
                        Upload your word document
                    </Typography>
                    <Button
                        variant="contained"
                        startIcon={<DescriptionIcon />}
                        sx={{
                            backgroundColor: "black",
                            color: "white",
                            textTransform: "none",
                            borderRadius: 2,
                            "&:hover": {
                            backgroundColor: "#333",
                            },
                        }}
                        onClick={handleOpenModal}
                    >
                        How to prepare
                    </Button>
                </Box>
                <Instruction
                    open={isModalOpen}
                    onClose={handleCloseModal}
                />
                <Box sx={{ mt: 2 }}>
                    <DocxReader />
                </Box>
                <Box sx={{ mt: 2 }}>
                    <PrepareArticle />
                    <GeneralError />
                    <ErrorWithInstruction />
                    <ArticleDetails />
                </Box>
            </Container>
        </Box>
    );
};

export default ArticleFormatter;