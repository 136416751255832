import React from "react";
import { Box, Container, Typography } from "@mui/material";
import DocxReader from "./DocxReader";
import DownloadPreparedArticle from "./DownloadPreparedArticle";
import Feedback from "./Feedback";
import JournalOrBibliographyDropDown from "./JournalOrBibliographyDropDown";
import PublishersDropDown from "./PublishersDropDown";
import { useArticleContext } from "../contexts/ArticleContext";

const ArticleFormatter = () => {
    const {
        publisher,
        journal,
        bibliography,
        article
    } = useArticleContext();

    return (
        <Box>
            <Container maxWidth="md" sx={{ mt: 4 }}>
                <Typography variant="h4" gutterBottom align="center">
                    Format your article for any Journal in seconds
                </Typography>
                <Typography variant="h6" gutterBottom align="center" marginTop={4}>
                    Specify where you want to publish
                </Typography>
                <PublishersDropDown />
                <Box sx={{ mt: 2 }}>
                    <JournalOrBibliographyDropDown />
                </Box>
                <Box sx={{ mt: 2 }}>
                    <DocxReader />
                </Box>
                {article && (bibliography || journal) && publisher && (
                    <Box sx={{ mt: 2 }}>
                        <DownloadPreparedArticle />
                    </Box>
                )}
                <Box sx={{ mt: 2 }}>
                    <Feedback />
                </Box>
            </Container>
        </Box>
    );
};

export default ArticleFormatter;