import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { useErrorContext } from '../contexts/ErrorContext';

const GeneralError = () => {
  const { generalError, setGeneralError } = useErrorContext();

  if (!generalError) return null;

  return (
    <Alert severity="error" sx={{ width: '100%', mt: 2 }} onClose={() => setGeneralError(null)}>
      <AlertTitle>Error</AlertTitle>
      {generalError}
    </Alert>
  );
};

export default GeneralError;