import { Button, Dialog, DialogTitle } from "@mui/material"
import client from '../api/client';
import { getAuth } from "firebase/auth";
import { useState } from "react";
import Checkout from "./Checkout";

const PayButton = () => {
   const [clientSecret, setClientSecret] = useState<string>("");
   const [open, setOpen] = useState(false);

    const handlePayToDownload = async () => {
        const auth = getAuth();
        const currentUser = auth.currentUser;
        if (!currentUser) {
            throw new Error("User is not authenticated");
        }

        const token = await currentUser.getIdToken();

        const response = await client.post('/create-payment-intent', {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }
        );

        const { clientSecret } = response.data
        if (!clientSecret) {
            throw new Error("Client secret is not received");
        }

        setClientSecret(clientSecret);
        setOpen(true);
    }

    const handleClose = () => setOpen(false);

    return (
        <>
            <Button
                onClick={handlePayToDownload}
                variant="contained"
                color="primary"
                sx={{ minWidth: 150, textTransform: 'none', borderRadius: 2, backgroundColor: "#069494" }}
            >
                Download PDF for 19,99 PLN
            </Button>

            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>Checkout form</DialogTitle>
                <Checkout clientSecret={clientSecret} />
            </Dialog>
        </>
    )
}

export default PayButton;