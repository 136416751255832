import React, {useState} from "react";
import {
    getAuth,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    updateProfile
} from "firebase/auth";
import {initializeApp} from "firebase/app";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {firebaseConfig} from "../config/firebaseConfig";
import {
    Box,
    Button,
    TextField,
    Typography,
    Checkbox,
    Divider,
    Paper,
    IconButton,
    InputAdornment,
    Link 
} from "@mui/material";
import {useMixpanel} from "../contexts/MixpanelContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PrivacyPolicy from "../components/PrivacyPolicy";
import GoogleLoginWithTerms from "../components/GoogleLoginWithTerms";
import InformationGraphic from "../components/InformationGraphic";

const Register = () => { 
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const mixpanel = useMixpanel();
    const eventName = "fe_register";

    const handleTogglePassword = () => {
        setShowPassword((prev) => !prev);
    };

    const handleToggleConfirmPassword = () => {
        setShowConfirmPassword((prev) => !prev);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };
    
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleRegister = async (e: React.FormEvent) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError("Passwords do not match");
        } else if (!termsAccepted) {
            setError("Terms of Service are not accepted.")
        } else {
            mixpanel.startTimeMeasurement(eventName);
            try {
                // Create user account
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                
                // Update user profile with first and last name
                await updateProfile(userCredential.user, {
                    displayName: `${firstName} ${lastName}`
                });
                
                // Store first and last name separately in localStorage for easy access
                localStorage.setItem("firstName", firstName);
                localStorage.setItem("lastName", lastName);
                
                // Send verification email
                await sendEmailVerification(userCredential.user);
                mixpanel.trackEvent(eventName, {success: true});
                navigate('/verify-email');
            } catch (err: any) {
                mixpanel.trackEvent(eventName, {success: false});
                setError(err.message);
            }
        }
    };

    return (
        <Box
            sx={{
                backgroundColor: "#f5f6f6",
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: 2
            }}
        >
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Box
                    sx={{
                        width: 60,
                        height: 60,
                        borderRadius: "50%",
                        backgroundColor: "#ffffff",
                        border: "1px solid #069494",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <img src="/logo.svg" alt="DrPaper Logo" width={30} height={30} />
                </Box>
                <Typography variant="h5" fontWeight="bold" mt={2}>
                    DrPaper
                </Typography>
                <Typography
                    variant="h5"
                    fontWeight="bold"
                    color="#069494"
                    mt={4}
                    sx={{
                        textAlign: "center",
                        lineHeight: "1.25"
                    }}
                >
                    Format your article<br />for any Journal in seconds
                </Typography>
            </Box>

            <InformationGraphic />

            <Paper
                elevation={3}
                sx={{
                    padding: 4,
                    borderRadius: 2,
                    backgroundColor: "#ffffff",
                    width: "100%",
                    maxWidth: 400,
                    textAlign: "center"
                }}
            >
                <Typography variant="h6" fontWeight="bold" mb={5} mt={2}>
                    Create your account
                </Typography>
                <GoogleLoginWithTerms onError={setError}/>

                <Divider sx={{ width: "100%", my: 5 }}>or</Divider>

                <form onSubmit={handleRegister} style={{ width: "100%" }}>
                    <TextField
                        label="First Name"
                        type="text"
                        fullWidth
                        margin="normal"
                        size="small"
                        sx={{ mb: 2 }}
                        InputProps={{
                            style: {
                                borderRadius: "7px",
                            }
                        }}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                    />

                    <TextField
                        label="Last Name"
                        type="text"
                        fullWidth
                        margin="normal"
                        size="small"
                        sx={{ mb: 2 }}
                        InputProps={{
                            style: {
                                borderRadius: "7px",
                            }
                        }}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                    />

                    <TextField
                        label="Email"
                        type="email"
                        fullWidth
                        margin="normal"
                        size="small"
                        sx={{ mb: 2 }}
                        InputProps={{
                            style: {
                                borderRadius: "7px",
                            }
                        }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />

                    <TextField
                        label="Password"
                        type={showPassword ? "text" : "password"}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        size="small"
                        sx={{ mt:2, mb: 2 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={handleTogglePassword} edge="end">
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                            ),
                            style: {
                                borderRadius: "7px",
                            }
                        }}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />

                    <TextField 
                        label="Confirm Password" 
                        type={showConfirmPassword ? "text" : "password"} 
                        variant="outlined" 
                        fullWidth
                        margin="normal"
                        size="small"
                        sx={{ mt: 2 }} 
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                <IconButton onClick={handleToggleConfirmPassword} edge="end">
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                                </InputAdornment>
                            ),
                            style: {
                                borderRadius: "7px",
                            }
                        }}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                    {error && <Typography color="error">{error}</Typography>}
                    <Box display="flex" alignItems="left" width="100%">
                        <Box display="flex" alignItems="left">
                            <Checkbox
                                size="medium"
                                sx={{
                                    color: "#069494",
                                    '&.Mui-checked': { color: "#069494" }
                                }}
                                checked={termsAccepted}
                                onChange={e => setTermsAccepted(e.target.checked)}
                            />
                            <Typography variant="body2" color="grey" textAlign={"left"}>
                                By registering, you confirm you have read and agree to
                                <Link onClick={openModal} style={{ cursor: "pointer", marginLeft: "5px", textDecoration: "underline" }}>
                                    DrPaper's Terms of Service.*
                                </Link>
                                <PrivacyPolicy isOpen={isModalOpen} onClose={closeModal}/>
                            </Typography>
                        </Box>
                    </Box>
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        sx={{
                            mt: 4,
                            mb: 2,
                            backgroundColor: "#069494",
                            maxWidth: "50%",
                            mx: "auto",
                            fontWeight: "bold",
                            textTransform: 'none',
                            fontSize: "18px",
                            borderRadius: 2
                        }}
                    >
                        Sign up
                    </Button>
                </form>
                <Typography variant="h6" fontWeight="bold" mt={2}>
                    Already have an account? {" "}
                    <Typography
                        component={RouterLink}
                        to="/login"
                        variant="h6"
                        fontWeight="bold"
                        color="#069494"
                        sx={{
                            cursor: "pointer",
                            textDecoration: "none"
                        }}
                    >
                        Log in
                    </Typography>
                </Typography>
            </Paper>
        </Box>
    );
};

export default Register;