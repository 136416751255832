import { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import FeedbackButton from "./FeedbackButton";
import FeedbackForm from "./FeedbackForm";

const FeedbackContainer = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <FeedbackButton onClick={handleOpen} />

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ textAlign: "center" }}>Your Thoughts Matter - Help Us Improve!</DialogTitle>
        <DialogContent>
          <FeedbackForm />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            sx={{
              textTransform: 'none',
              color: '#bdbdbd',
              fontWeight: "bold"
            }}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FeedbackContainer;