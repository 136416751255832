import { Button } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";

interface FeedbackButtonProps {
  onClick: () => void;
}

const FeedbackButton = ({ onClick }: FeedbackButtonProps) => {
  return (
    <div style={{ position: "fixed", bottom: "20px", right: "20px" }}>
      <Button
        onClick={onClick}
        startIcon={<EmailIcon />}
        variant="contained"
        color="primary"
        sx={{ margin: 1, backgroundColor: "#069494" }}
      >
        Feedback
      </Button>
    </div>
  );
};

export default FeedbackButton;