import React, { useEffect, useState, useCallback } from 'react';
import { Box, Typography, Paper, Alert, AlertTitle, CircularProgress, List, ListItem, ListItemText, IconButton } from '@mui/material';
import { useConvertDocxToArticle } from '../api/endpoints/useConvertDocxToArticle';
import Instruction from './Instruction';
import { useArticleContext } from '../contexts/ArticleContext';
import CloseIcon from '@mui/icons-material/Close';
import { Chapter } from '../model/Chapter';

const countContentElements = (chapters: Chapter[]) => {
  let imageCount = 0;
  let tableCount = 0;

  const traverseChapters = (chapters: Chapter[]) => {
    chapters.forEach((chapter) => {
      chapter.contents.forEach((content) => {
        if (content.type === 'image') {
          imageCount++;
        } else if (content.type === 'table') {
          tableCount++;
        }
      });

      if (chapter.subChapters && chapter.subChapters.length > 0) {
        traverseChapters(chapter.subChapters);
      }
    });
  };

  traverseChapters(chapters);
  return { imageCount, tableCount };
};

const DocxReader = () => {
  const [file, setFile] = useState<File | null>(null);
  const { article, setArticle } = useArticleContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [dragActive, setDragActive] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const convertDocxToArticle = useConvertDocxToArticle();

  useEffect(() => {
    if (!file) return;

    const parseDocx = async () => {
      setLoading(true);
      setError(null);
      try {
        const parsedArticle = await convertDocxToArticle(file);
        setArticle(parsedArticle);
      } catch (error) {
        if (typeof error === 'string') {
          setError(error);
        } else if (error && typeof error === 'object' && 'message' in error) {
          setError((error as { message: string }).message);
        } else {
          setError('An unexpected error occurred.');
        }
      } finally {
        setLoading(false);
      }
    };

    parseDocx();
  }, [file]);

  const handleFileValidation = (uploadedFile: File) => {
    if (uploadedFile.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setError('The uploaded file is not a valid DOCX file. Please upload a file with the *.docx extension.');
      return false;
    }
    setError(null);
    return true;
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = event.target.files?.[0];
    if (uploadedFile && handleFileValidation(uploadedFile)) {
      setFile(uploadedFile);
    }
  };

  const handleDrag = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setDragActive(true);
    } else if (event.type === 'dragleave') {
      setDragActive(false);
    }
  }, []);

  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const droppedFile = event.dataTransfer.files[0];
      if (handleFileValidation(droppedFile)) {
        setFile(droppedFile);
      }
    }
  }, []);

  const handleClick = () => {
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setArticle(null);
    setError(null);
  };

  return (
    <Box
      sx={{
        minWidth: 500,
        margin: '0 auto',
        padding: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {loading ? (
        <>
          <Typography variant="h6" component="h2" gutterBottom>
            We're interpreting your file
          </Typography>
          <CircularProgress />
        </>
      ) : (
        <>
          {!file ? (
            <>
            <Typography variant="h6" component="h2" gutterBottom>
              Upload your article in *.docx format
            </Typography>
            <Paper
              id="drag-drop-zone"
              onDragEnter={handleDrag}
              onDragOver={handleDrag}
              onDragLeave={handleDrag}
              onDrop={handleDrop}
              onClick={handleClick}
              sx={{
                border: dragActive ? '2px dashed #0066cc' : '2px dashed #999',
                borderRadius: 2,
                padding: 4,
                textAlign: 'center',
                marginBottom: 2,
                cursor: 'pointer',
              }}
            >
              <Typography>
                {dragActive ? 'Drop the file here...' : 'Drag & Drop a DOCX file here, or click to upload'}
              </Typography>

              <input
                id="fileInput"
                type="file"
                accept=".docx"
                onChange={handleFileUpload}
                style={{ display: 'none' }}
              />
            </Paper>
          </>
          ) : (
            <Box mt={2} sx={{ textAlign: 'center', position: 'relative', width: '100%' }}>
              <Typography variant="body1">
                Selected File: <strong>{file.name}</strong>
              </Typography>
              <IconButton
                aria-label="Remove file"
                onClick={handleRemoveFile}
                sx={{ position: 'absolute', top: 0, right: 0 }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}

          <Instruction />

          {error && (
            <Alert severity="error" sx={{ mt: 2, width: '100%' }}>
              <AlertTitle>Error</AlertTitle>
              {error}
            </Alert>
          )}

          {article && (
            <Box mt={2}>
              <Typography variant="body1">
                Detected elementes in file:
              </Typography>
              <List>
                <ListItem>
                  <ListItemText 
                    primary="Title"
                    secondary={
                      article.title ? (
                        article.title
                      ) : (
                        <Typography component="span" style={{ color: 'red' }}>
                          No Title detected, please apply Title Style in Word(check INSTRUCTIONS above).
                        </Typography>
                      )
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Authors"
                    secondary={
                      article.authors ? (
                        article.authors
                      ) : (
                        <Typography component="span" style={{ color: 'red' }}>
                          No Authors detected, please point to the Authors list in Word using "Authors: " prefix.
                        </Typography>
                      )
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Abstract"
                    secondary={
                      article.abstract ? (
                        article.abstract
                      ) : (
                        <Typography component="span" style={{ color: 'red' }}>
                          No Abstract detected, please point to the Abstract in Word using "Abstract: " prefix.
                        </Typography>
                      )
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Keywords"
                    secondary={
                      article.keywords ? (
                        article.keywords.join(', ')
                      ) : (
                        <Typography component="span" style={{ color: 'red' }}>
                          No Keywords detected, please point to the Keywords list in Word using "Keywords: " prefix.
                        </Typography>
                      )
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Chapters"
                    secondary={
                      article.chapters ? (
                        `${article.chapters.length} chapters`
                      ) : (
                        <Typography component="span" style={{ color: 'red' }}>
                          No Chapters detected, please apply Heading 1 Style in Word(check INSTRUCTIONS above).
                        </Typography>
                      )
                    }

                  />
                </ListItem>
                {article.chapters && (() => {
                  const { imageCount, tableCount } = countContentElements(article.chapters);
                  return (
                    <>
                      <ListItem>
                        <ListItemText primary="Images" secondary={`${imageCount} images`} />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Tables" secondary={`${tableCount} tables`} />
                      </ListItem>
                    </>
                  );
                })()}
              </List>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default DocxReader;