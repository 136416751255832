import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

interface InstructionProps {
  open: boolean;
  onClose: () => void;
}

const Instruction: React.FC<InstructionProps> = ({ open, onClose }) => {

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle>
        Instruction
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography noWrap sx={{ mb: 2 }}>
            1. Use <strong>Mendeley</strong> or <strong>Zotero</strong> to create references (Citavia and EndNote coming soon)
          </Typography>
          <Typography noWrap sx={{ mb: 2 }}>
            2. Use MS Word styles (<strong>Heading 1</strong>, <strong>Heading 2</strong>, etc.) to tag Chapters and Subchapters
          </Typography>
          <Typography noWrap sx={{ mb: 2 }}>
            3. <strong>DO NOT</strong> use List to tag Chapters:
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mb: 2, position: "relative" }}>
            <Box sx={{ position: "relative", width: "40%" }}>
              <img src="/instruction-image2.png" alt="instruction image 2" style={{ width: "100%", height: "auto" }} />
              <ClearIcon 
                sx={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  color: "red",
                  fontSize: 150
                }}
              />
            </Box>
            <Box sx={{ position: "relative", width: "60%" }}>
              <img src="/instruction-image1.png" alt="instruction image 1" style={{ width: "100%", height: "auto" }} />
              <DoneIcon 
                sx={{
                  position: "absolute",
                  top: 10,
                  left: 10,
                  color: "green",
                  fontSize: 150
                }}
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default Instruction;
