import { useNavigate } from "react-router-dom";
import DownloadArticleButton from "../components/DownloadArticleButton";
import { Box, Button, Paper, Typography } from "@mui/material";

const PaymentComplete = () => {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                backgroundColor: "#f5f6f6",
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    padding: 4,
                    borderRadius: 2,
                    backgroundColor: "#ffffff",
                    fittocontent: "100%",
                    textAlign: "center"
                }}
            >
                <Typography variant="h5" fontWeight="bold" mb={2} mt={2}>
                    Thank you for Your Payment!
                </Typography>
                <Typography variant="body1" mb={2} mt={2}>
                    By using DrPaper, you're supporting a startup that aims to accelerate scientific progress
                </Typography>
                <DownloadArticleButton /> <br />
                <Button
                    onClick={() => navigate('/')}
                    variant="text"
                    sx={{
                        mt: 2,
                        textTransform: 'none',
                        color: '#bdbdbd',
                        fontWeight: "bold",
                    }}
                >
                    Back to Formatter
                </Button>
            </Paper>
        </Box>
    );
}

export default PaymentComplete;