import client from '../client';
import { AxiosResponse } from 'axios';
import { getAuth } from 'firebase/auth';

/**
 * Generates a PDF by sending the article, publisher, journal, and bibliography
 * to the document generation endpoint.
 *
 * @param zipFile - The ZIP file containing the article and additional data.
 * @param publisher - The publisher for which the PDF is being generated.
 * @param journal - The journal for which the PDF is being generated.
 * @param bibliography - The bibliography style to use for citations.
 * @returns A Promise that resolves to a Blob representing the generated PDF.
 */
export const generatePdf = async (
  zipFile: File,
  publisher: string,
  journal: string,
  bibliography: string
): Promise<Blob> => {
  const endpoint = `/document`;

  const formData = new FormData();
  formData.append('file', zipFile, zipFile.name);
  formData.append('publisher', publisher.toLowerCase());
  formData.append('journal', journal.toLowerCase());
  formData.append('bibliography', bibliography.toLowerCase());

  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error("User is not authenticated");
    }

    const token = await currentUser.getIdToken();

    const response: AxiosResponse<Blob> = await client.post(endpoint, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
      responseType: 'blob',
    });

    return response.data;
  } catch (error: any) {
    console.error(`Error generating PDF: ${error.message}`);
    return Promise.reject(error);
  }
};