import { getAuth } from 'firebase/auth';
import client from '../client';
import { AxiosResponse } from 'axios';

const getAuthToken = async (): Promise<string> => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  if (!currentUser) {
    throw new Error("User is not authenticated");
  }
  return await currentUser.getIdToken();
};

export const getPublishers = async (): Promise<string[]> => {
  const token = await getAuthToken();
  const endpoint = `/publishers`;
  return client
    .get<string[]>(endpoint, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Failed to fetch publishers: ${error.message}`);
      return Promise.reject(error);
    });
};

export const getJournalsByPublisher = async (publisher: string): Promise<string[]> => {
  const token = await getAuthToken();
  const endpoint = `/publishers/${publisher}/journals`;
  return client
    .get<string[]>(endpoint, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error(`Failed to fetch journals for publisher '${publisher}': ${error.message}`);
      return Promise.reject(error);
    });
};

export const getBibliography = async (): Promise<Map<string, string[]>> => {
  const token = await getAuthToken();
  const endpoint = `/publishers/bibliography`;
  return client
    .get(endpoint, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then((response: AxiosResponse<Map<string, string[]>>) => {
      let styles: Map<string, string[]> = response.data;
      return styles;
    })
    .catch((error) => {
      console.error(`Failed to fetch bibliography styles: ${error.message}`);
      return Promise.reject(error);
    });
};